<template>
  <div>
    <crud-table
      v-if="brandList.length"
      ref="crudTable"
      :list-items="listItems"
      :show-brands-filter="true"
      :show-search-filter="true"
      :show-rides-to-invoice-filter="true"
      :brand-list="brandList"
      :region-list="regionList"
      :showCreate="false"
      :is-editable="false"
      :is-deleteble="false"
      :isDisabledDeletedFilter="true"
      :has-rides-to-invoice="true"
      row-click-path="/tables/drivers"
      endpoint="drivers"
      heading="generate drivers invoices"
      @getData="getData"
    />
  </div>
</template>

<script>
import CrudTable from '../../../modules/admin/CrudTable.vue'
import UsersInput from '../../../modules/components/UsersInput.vue'
import AddressInput from '../../../modules/components/AddressInput.vue'
import Request from '../../../helpers/request'
import Brands from '../../../helpers/brands'
import { TEXT_FIELD_MAX_LENGTH, BRAND } from '../../../constants/common'

export default {
  components: { CrudTable, UsersInput, AddressInput },
  mixins: [Request, Brands],
  data () {
    return {
      TEXT_FIELD_MAX_LENGTH,
      BRAND,
      typesList: [],
      brandList: [],
      regionList: [],
      certificatesList: [],
      listItems: [
        { text: 'ID', value: 'id' },
        { text: 'Avatar', value: 'photo', textValue: 'url', image: true },
        { text: 'User id', value: 'user', textValue: 'id' },
        { text: 'Brands', value: 'brands', objectKey: 'name' },
        { text: 'Name', value: 'name' },
        { text: 'Tariffs', custom: (item) => 
        `<div style="font-size: 13px">
          ${item.tariffs.driveme ? `<span><b>driveme:</b>&nbsp;${item.tariffs.driveme}</span><br>` : ''}
          ${item.tariffs.edrivers ? `<span><b>edrivers:</b>&nbsp;${item.tariffs.edrivers}</span><br>` : ''}
          ${item.tariffs.rentabob ? `<span><b>rentabob:</b>&nbsp;${item.tariffs.rentabob}</span>` : ''}
        </div>` },
        { text: 'Description', value: 'description' },
        { text: 'Region', value: 'regions', objectKey: 'name' },
        { text: 'Address', value: 'address' },
        { text: 'Min Hours Per Ride', value: 'minHoursPerRide', textValue: 'time' },
        { text: '', value: null }
      ],
      csvData: [],
      loading: true,
      data: [],
      editDialog: {
        display: false,
        saveFunction: () => {},
        data: {}
      },
      files: {},
      imageData: null,
      driverTypes: {
        driveme: null,
        rentabob: null,
        edrivers: null
      }
    }
  },
  watch: {
    'editDialog.data.brands': {
      handler () {
        if (!this.editDialog.data.brands || !this.editDialog.data.brands.length) {
          this.driverTypes.driveme = null
          this.driverTypes.rentabob = null
          this.driverTypes.edrivers = null
        }
        else if (this.brandList.length) {
          if (!this.editDialog.data.brands.includes(this.brandList.filter(item => item.name === BRAND.DRIVE_ME).find(item => item.id).id)) {
            this.driverTypes.driveme = null
          }

          if (!this.editDialog.data.brands.includes(this.brandList.filter(item => item.name === BRAND.RENTABOB).find(item => item.id).id)) {
            this.driverTypes.rentabob = null
          }

          if (!this.editDialog.data.brands.includes(this.brandList.filter(item => item.name === BRAND.EDRIVERS).find(item => item.id).id)) {
            this.driverTypes.edrivers = null
          }
        }
      },
      deep: true
    }
  },
  computed: {
    driverTypesAll() {
			let result = []
      if (this.driverTypes.driveme) {
        result.push(this.driverTypes.driveme)
      }
      if (this.driverTypes.rentabob) {
        result.push(this.driverTypes.rentabob)
      }
      if (this.driverTypes.edrivers) {
        result.push(this.driverTypes.edrivers)
      }
      return result
		},
  },
  async beforeMount () {
    let getCertificatesList = new Promise((resolve) => {
      this.request('GET', '/driver-certificates?pageSize=9999', {}, ({ data }) => {
        this.certificatesList = data.data.map(item => ({ ...item, obtainedAt: '' }))
        resolve()
      })
    })

    let getRegionList = new Promise((resolve) => {
      this.request('GET', '/regions?pageSize=9999', {}, ({ data }) => {
        this.regionList = data.data
        resolve()
      })
    })

    let getTypesList = new Promise((resolve) => {
      this.request('GET', '/driver-types?pageSize=9999', {}, ({ data: driverTypesData }) => {
        this.typesList = driverTypesData.data
        resolve()
      })
    })

    Promise.all([getCertificatesList, getRegionList, getTypesList]).then(() => {
      
    })

    try {
      this.brandList = await this.fetchBrands()
    } catch (e) {
      throw new Error(e)
    }
  },
  methods: {
    typesListBrand (brandName) {
      return this.typesList.filter(item => item.brand.name === brandName)
    },
    previewFiles (event) {
      this.files = this.$refs.myFiles.files

      var input = event.target

      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.editDialog.data.imageData = e.target.result
          this.editDialog.data.imageName = input.files[0].name
          this.$forceUpdate()
        }

        reader.readAsDataURL(input.files[0])
      }
    },
    getData (drivers) {
      this.csvData = drivers.map(driver => ({
        driver: `${driver.name || '-'}`,
        address: driver.address || '-',
        email: driver.user.email || '-',
        phone: driver.user.phone ? driver.user.phone.number : '-',
        description: driver.description || '-',
        id: driver.id
      }))
    },
    sendWelcome () {
      let body = {
        data: {
          driver: this.editDialog.data.id
        }
      }

      this.request('POST', '/emailing/driver-set-password', body, ({ data }) => {
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Welcome message has been sent!'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.image-preview {
    img {
      width: 124px;
      height: 124px;
      background-color: white;
      border: 1px solid #DDD;
      padding: 5px;
    }
}
</style>
